import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";

function HomeComponent(props) {

  const initialState = {
    name: '',
    email: '',
    phone: '',
    area_1: 'Wybierz',
    regulation: false,
    processing: false
  }

  const [data, setData] = useState(initialState);
  const [showList, setShowList] = useState(false);
  const [errors, setErrors] = useState({});
  const [formSended, setformSended] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const errorExist = checkErrors();

    if (errorExist === false) {
      props.insertMethod(data).then((result) => {
        if (!result) {
          setErrors({ global: 'Wystąpił błąd podczas wysyłania formularza, skontaktuj się z administratorem' })
        } else {
          setformSended(true);
          setData(initialState);
        }
      });
    }
  }

  const checkErrors = () => {
    setErrors({});

    let errors = {};

    if (data.phone === "" && data.email === "") {
      errors.email = "Jedno z tych pól musi zostać uzupełnione";
      errors.phone = "Jedno z tych pól musi zostać uzupełnione";
    }

    if (data.regulation === false) {
      errors.regulation = "To pole musi zostać uzupełnione";
    }

    if (data.processing === false) {
      errors.processing = "To pole musi zostać uzupełnione";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return Object.keys(errors).length > 0;
    }

    return false;
  }

  const handleChange = (fieldName, value) => {

    setData({ ...data, [fieldName]: value });
    if (fieldName === 'area_1') {
      setShowList(false);
    }
  }

  const handleShowList = () => {
    setShowList(true);
  }

  return (<Fragment>
    <div id="wrapper" className="home">
      <div id="page">
        <div id="primary">
          <div className="title">
            <h2>Jeśli chcesz poznać szczegóły oferty IDACIO</h2>
            <p>– pozostaw nam swoje dane, a my odezwiemy się do ciebie</p>
          </div>
          <div id="form">
            <form onSubmit={handleSubmit}>
              {formSended && <div class="done">Formularz został wysłany!</div>}
              <input type="text" name="name" placeholder="Imię i Nazwisko" onChange={(e) => handleChange('name', e.target.value)} value={data.name} />
              {errors.email && <span className="error">{errors.email}</span>}
              <input type="text" name="mail" placeholder="E-mail*" onChange={(e) => handleChange('email', e.target.value)} value={data.email} />
              {errors.phone && <span className="error">{errors.phone}</span>}
              <input type="text" name="phone" placeholder="Telefon*" onChange={(e) => handleChange('phone', e.target.value)} value={data.phone} />
              <div className="custom_select">
                <div className="select-selected select-arrow-active" onClick={handleShowList}>{data.area_1}</div>
                {showList && <div className="select-items">
                  <div onClick={() => handleChange('area_1', 'Kujawsko-pomorskie')}>Kujawsko-pomorskie</div>
                  <div onClick={() => handleChange('area_1', 'Lubelskie')}>Lubelskie</div>
                  <div onClick={() => handleChange('area_1', 'Lubuskie')}>Lubuskie</div>
                  <div onClick={() => handleChange('area_1', 'Łódzkie')}>Łódzkie</div>
                  <div onClick={() => handleChange('area_1', 'Małopolskie')}>Małopolskie</div>
                  <div onClick={() => handleChange('area_1', 'Mazowieckie')}>Mazowieckie</div>
                  <div onClick={() => handleChange('area_1', 'Opolskie')}>Opolskie</div>
                  <div onClick={() => handleChange('area_1', 'Podkarpackie')}>Podkarpackie</div>
                  <div onClick={() => handleChange('area_1', 'Podlaskie')}>Podlaskie</div>
                  <div onClick={() => handleChange('area_1', 'Pomorskie')}>Pomorskie</div>
                  <div onClick={() => handleChange('area_1', 'Śląskie')}>Śląskie</div>
                  <div onClick={() => handleChange('area_1', 'Świętokrzyskie')}>Świętokrzyskie</div>
                  <div onClick={() => handleChange('area_1', 'Warmińsko-mazurskie')}>Warmińsko-mazurskie</div>
                  <div onClick={() => handleChange('area_1', 'Wielkopolskie')}>Wielkopolskie</div>
                  <div onClick={() => handleChange('area_1', 'Zachodniopomorskie')}>Zachodniopomorskie</div>
                </div>}
              </div>
              {errors.regulation && <span className="error">{errors.regulation}</span>}
              <p><input type="checkbox" name="regulations" onChange={(e) => handleChange('regulation', !data.regulation)} checked={data.regulation} />* Akceptuje <Link to="files/regulamin.pdf" target="_blank">Regulamin</Link></p>
              {errors.processing && <span className="error">{errors.processing}</span>}
              <p><input type="checkbox" name="rodo" onChange={(e) => handleChange('processing', !data.processing)} checked={data.processing} />* Wyrażam zgodę na <Link to="files/przetwarzanie_danych.pdf" target="_blank">przetwarzanie danych</Link></p>
              {!errors.global && <span className="error">{errors.global}</span>}
              <input type="submit" value="Wyślij" className="sendit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
  );
}

export default HomeComponent;
