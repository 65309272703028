import React, { useState } from 'react';

// import exportFromJSON from 'export-from-json'
import DataComponent from '../components/data.component';
import { config } from '../config/config';


function DataContainer() {

  const [personData, setPersonData] = useState([]);
  console.log(personData);

  // async function downloadData() {

  //   const response = await fetch(`${config.host}/api/my-dog.php`, { method: 'GET' });
  //   const dataJSON = await response.json();

  //   if (dataJSON.success === true) {

  //     const data = dataJSON.documents;
  //     const fileName = `dane_personalne-${new Date().getTime()}`;
  //     const exportType = 'xls';
  //     const withBOM = true;

  //     exportFromJSON({ data, fileName, exportType, withBOM });
  //   } else {
  //     return false;
  //   }
  // }

  async function checkPass(password) {

    let formData = new FormData();

    formData.append('7GDcVGOP', password);

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    const response = await fetch(`${config.host}/api/check.php`, requestOptions);
    const data = await response.json();

    if (data.success === true) {
      setPersonData(data.documents);
      return true;
    } else {
      return false;
    }
  }

  return (
    <DataComponent
      // downloadData={downloadData}
      checkPass={checkPass}
      personData={personData}
    />
  );
}

export default DataContainer;
