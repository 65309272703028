import React, { Fragment, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../assets/global.css';

function DataComponent({ checkPass, downloadData, personData }) {

  const [data, setData] = useState({ password: '' });
  const [enableDownload, setEnableDownload] = useState(false);

  const handleSubmit = () => {
    checkPass(data.password).then(result => {
      if (result) {
        setEnableDownload(true);
      }
    });
  }

  // const handleDownloadData = () => {
  //   if (enableDownload) {
  //     downloadData();

  //     setData({ password: '' });
  //     setEnableDownload(false);
  //   }
  // }

  const renderPersonData = () => {
    return <Fragment>
      {personData && personData.length > 0 ? personData.map((person, index) => {
        console.log(person);
        return <tr>
          <td>{index + 1}</td>
          <td>{person.name ? person.name : '-'}</td>
          <td>{person.email ? person.email : '-'}</td>
          <td>{person.phone ? person.phone : '-'}</td>
          <td>{person.area_1 ? person.area_1 : '-'}</td>
          <td>{person.regulation ? 'tak' : 'nie'}</td>
          <td>{person.processing ? 'tak' : 'nie'}</td>
        </tr>
      }) : <tr>
        <td colspan="6" style={{ textAlign: 'center' }}>brak danych do wyświetlenia</td>
      </tr>}
    </Fragment>
  }

  return (
    <div className="data-container">
      <div className="button-wrapper">
        {!enableDownload && <div className="button-wrapper">
          <input type="text" name="phone" placeholder="Wpisz hasło" onChange={(e) => setData({ password: e.target.value })} />
          <input type="submit" value="odblokuj dostęp" onClick={handleSubmit} className="sendit" />
        </div>}
        {enableDownload && <div className="button-wrapper">
          {/* <input type="submit" value="pobierz dane do Exela" onClick={handleDownloadData} className="sendit" /> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={`dane_personalne-${new Date().getTime()}`}
            sheet="dane personalne"
            buttonText="Pobierz do Exela" />
        </div>}
      </div>
      {enableDownload && <div className="table-wrapper">
        <table id="table-to-xls">
          <thead>
            <tr>
              <th>Lp</th>
              <th>Imie i nazwisko</th>
              <th>E-mail</th>
              <th>Telefon</th>
              <th>Powiat</th>
              <th>Zgoda na regulmain</th>
              <th>Zgoda na przetwarzanie danych</th>
            </tr>
          </thead>
          <tbody>
            {renderPersonData()}
          </tbody>
        </table>
      </div>}
    </div>
  );
}

export default DataComponent;
