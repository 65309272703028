import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import HomeContainer from "./containers/home.container";
import DataContainer from "./containers/data.container";

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/pobierz-dane">
          <DataContainer />
        </Route>
        <Route path="/">
          <HomeContainer />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
