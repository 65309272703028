import React from 'react';
import { config } from '../config/config';
import HomeComponent from '../components/home.component';

function HomeContainer() {

  async function insertMethod(dataJSON) {

    let formData = new FormData();

    formData.append('name', dataJSON.name);
    formData.append('email', dataJSON.email);
    formData.append('phone', dataJSON.phone);
    formData.append('area_1', dataJSON.area_1);
    formData.append('regulation', dataJSON.regulation ? '1' : '0');
    formData.append('processing', dataJSON.processing ? '1' : '0');

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    const response = await fetch(`${config.host}/api/my-dog.php`, requestOptions);
    const data = await response.json();

    if (data.success === true) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <HomeComponent insertMethod={insertMethod} />
  );
}

export default HomeContainer;
